import React from 'react';
import Layout from '../components/Layout'
import { PageHeader } from '../components/PageHeader';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings } = data;
  const { title } = wordpressWpSettings;
  return (
    <Layout>
      <SEO title={`404 | ${title}`} />
      <PageHeader headerTitle="404 Not Found!" headerSubTitle={`Unfortunately we could not find that address!`} compact={false} location={location} />
      <p>Please click <Link to={'/'}>Here</Link>to go back to the homepage.</p>
    </Layout>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
    }
  }
`
